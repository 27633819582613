import { default as __imroojeXczdaQ4NKuMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/__imrooje.vue?macro=true";
import { default as d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/1122-be56/d7a92580-2619-11ee-be56-0242ac120002.vue?macro=true";
import { default as _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/47642716-34d9-4ee1-a7cb-5668cb07c7c1.vue?macro=true";
import { default as c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/c7a92580-2619-11ee-be56-0242ac120cab1.vue?macro=true";
import { default as directingj10636eXzvMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/directing.vue?macro=true";
import { default as groups7e29OKjhRjMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/groups.vue?macro=true";
import { default as b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/b8a91580-2619-11ee-be56-0242ac120002.vue?macro=true";
import { default as c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/c7a92580-2619-11ee-be56-0242ac120002.vue?macro=true";
import { default as directingf3TDVdJL5dMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/directing.vue?macro=true";
import { default as groupsUBQD6iGIJLMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/groups.vue?macro=true";
import { default as indexkxE5o4J7YzMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/index.vue?macro=true";
import { default as jams14TsDqFpGCMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/jams.vue?macro=true";
import { default as new_formatsZM9g73uMfIMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/new_formats.vue?macro=true";
import { default as teachingEfMmnh4L0nMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/teaching.vue?macro=true";
import { default as code_45of_45conductRDPbFurFEcMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue?macro=true";
import { default as coming_soonEpB41iOIxhMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/coming_soon.vue?macro=true";
import { default as contact4LeFZG9Eu6Meta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue?macro=true";
import { default as faq8miDBWutYgMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue?macro=true";
import { default as indexSAk6ucXsQXMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue?macro=true";
import { default as privacydpJDnFbzfQMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue?macro=true";
import { default as team5jDEInuhgbMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue?macro=true";
import { default as terms_45and_45conditionsbX2zCs9FSvMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue?macro=true";
import { default as termsBm4XQbO4QkMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue?macro=true";
export default [
  {
    name: __imroojeXczdaQ4NKuMeta?.name ?? "__imrooje",
    path: __imroojeXczdaQ4NKuMeta?.path ?? "/__imrooje",
    meta: __imroojeXczdaQ4NKuMeta || {},
    alias: __imroojeXczdaQ4NKuMeta?.alias || [],
    redirect: __imroojeXczdaQ4NKuMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/__imrooje.vue").then(m => m.default || m)
  },
  {
    name: d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta?.name ?? "applications-1122-be56-d7a92580-2619-11ee-be56-0242ac120002",
    path: d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta?.path ?? "/applications/1122-be56/d7a92580-2619-11ee-be56-0242ac120002",
    meta: d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta || {},
    alias: d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta?.alias || [],
    redirect: d7a92580_452619_4511ee_45be56_450242ac120002WgBqKJeAOxMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/1122-be56/d7a92580-2619-11ee-be56-0242ac120002.vue").then(m => m.default || m)
  },
  {
    name: _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta?.name ?? "applications-47642716-34d9-4ee1-a7cb-5668cb07c7c1",
    path: _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta?.path ?? "/applications/47642716-34d9-4ee1-a7cb-5668cb07c7c1",
    meta: _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta || {},
    alias: _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta?.alias || [],
    redirect: _47642716_4534d9_454ee1_45a7cb_455668cb07c7c1TwzyXrvTjPMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/47642716-34d9-4ee1-a7cb-5668cb07c7c1.vue").then(m => m.default || m)
  },
  {
    name: c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta?.name ?? "applications-about-c7a92580-2619-11ee-be56-0242ac120cab1",
    path: c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta?.path ?? "/applications/about/c7a92580-2619-11ee-be56-0242ac120cab1",
    meta: c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta || {},
    alias: c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta?.alias || [],
    redirect: c7a92580_452619_4511ee_45be56_450242ac120cab1vArwqTJxxFMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/c7a92580-2619-11ee-be56-0242ac120cab1.vue").then(m => m.default || m)
  },
  {
    name: directingj10636eXzvMeta?.name ?? "applications-about-directing",
    path: directingj10636eXzvMeta?.path ?? "/applications/about/directing",
    meta: directingj10636eXzvMeta || {},
    alias: directingj10636eXzvMeta?.alias || [],
    redirect: directingj10636eXzvMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/directing.vue").then(m => m.default || m)
  },
  {
    name: groups7e29OKjhRjMeta?.name ?? "applications-about-groups",
    path: groups7e29OKjhRjMeta?.path ?? "/applications/about/groups",
    meta: groups7e29OKjhRjMeta || {},
    alias: groups7e29OKjhRjMeta?.alias || [],
    redirect: groups7e29OKjhRjMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/about/groups.vue").then(m => m.default || m)
  },
  {
    name: b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta?.name ?? "applications-b8a91580-2619-11ee-be56-0242ac120002",
    path: b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta?.path ?? "/applications/b8a91580-2619-11ee-be56-0242ac120002",
    meta: b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta || {},
    alias: b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta?.alias || [],
    redirect: b8a91580_452619_4511ee_45be56_450242ac120002SPGEhN9UYKMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/b8a91580-2619-11ee-be56-0242ac120002.vue").then(m => m.default || m)
  },
  {
    name: c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta?.name ?? "applications-c7a92580-2619-11ee-be56-0242ac120002",
    path: c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta?.path ?? "/applications/c7a92580-2619-11ee-be56-0242ac120002",
    meta: c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta || {},
    alias: c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta?.alias || [],
    redirect: c7a92580_452619_4511ee_45be56_450242ac120002Jnc9zHTkfnMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/c7a92580-2619-11ee-be56-0242ac120002.vue").then(m => m.default || m)
  },
  {
    name: directingf3TDVdJL5dMeta?.name ?? "applications-directing",
    path: directingf3TDVdJL5dMeta?.path ?? "/applications/directing",
    meta: directingf3TDVdJL5dMeta || {},
    alias: directingf3TDVdJL5dMeta?.alias || [],
    redirect: directingf3TDVdJL5dMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/directing.vue").then(m => m.default || m)
  },
  {
    name: groupsUBQD6iGIJLMeta?.name ?? "applications-groups",
    path: groupsUBQD6iGIJLMeta?.path ?? "/applications/groups",
    meta: groupsUBQD6iGIJLMeta || {},
    alias: groupsUBQD6iGIJLMeta?.alias || [],
    redirect: groupsUBQD6iGIJLMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/groups.vue").then(m => m.default || m)
  },
  {
    name: indexkxE5o4J7YzMeta?.name ?? "applications",
    path: indexkxE5o4J7YzMeta?.path ?? "/applications",
    meta: indexkxE5o4J7YzMeta || {},
    alias: indexkxE5o4J7YzMeta?.alias || [],
    redirect: indexkxE5o4J7YzMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/index.vue").then(m => m.default || m)
  },
  {
    name: jams14TsDqFpGCMeta?.name ?? "applications-jams",
    path: jams14TsDqFpGCMeta?.path ?? "/applications/jams",
    meta: jams14TsDqFpGCMeta || {},
    alias: jams14TsDqFpGCMeta?.alias || [],
    redirect: jams14TsDqFpGCMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/jams.vue").then(m => m.default || m)
  },
  {
    name: new_formatsZM9g73uMfIMeta?.name ?? "applications-new_formats",
    path: new_formatsZM9g73uMfIMeta?.path ?? "/applications/new_formats",
    meta: new_formatsZM9g73uMfIMeta || {},
    alias: new_formatsZM9g73uMfIMeta?.alias || [],
    redirect: new_formatsZM9g73uMfIMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/new_formats.vue").then(m => m.default || m)
  },
  {
    name: teachingEfMmnh4L0nMeta?.name ?? "applications-teaching",
    path: teachingEfMmnh4L0nMeta?.path ?? "/applications/teaching",
    meta: teachingEfMmnh4L0nMeta || {},
    alias: teachingEfMmnh4L0nMeta?.alias || [],
    redirect: teachingEfMmnh4L0nMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/teaching.vue").then(m => m.default || m)
  },
  {
    name: code_45of_45conductRDPbFurFEcMeta?.name ?? "code-of-conduct",
    path: code_45of_45conductRDPbFurFEcMeta?.path ?? "/code-of-conduct",
    meta: code_45of_45conductRDPbFurFEcMeta || {},
    alias: code_45of_45conductRDPbFurFEcMeta?.alias || [],
    redirect: code_45of_45conductRDPbFurFEcMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: coming_soonEpB41iOIxhMeta?.name ?? "coming_soon",
    path: coming_soonEpB41iOIxhMeta?.path ?? "/coming_soon",
    meta: coming_soonEpB41iOIxhMeta || {},
    alias: coming_soonEpB41iOIxhMeta?.alias || [],
    redirect: coming_soonEpB41iOIxhMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/coming_soon.vue").then(m => m.default || m)
  },
  {
    name: contact4LeFZG9Eu6Meta?.name ?? "contact",
    path: contact4LeFZG9Eu6Meta?.path ?? "/contact",
    meta: contact4LeFZG9Eu6Meta || {},
    alias: contact4LeFZG9Eu6Meta?.alias || [],
    redirect: contact4LeFZG9Eu6Meta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faq8miDBWutYgMeta?.name ?? "faq",
    path: faq8miDBWutYgMeta?.path ?? "/faq",
    meta: faq8miDBWutYgMeta || {},
    alias: faq8miDBWutYgMeta?.alias || [],
    redirect: faq8miDBWutYgMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexSAk6ucXsQXMeta?.name ?? "index",
    path: indexSAk6ucXsQXMeta?.path ?? "/",
    meta: indexSAk6ucXsQXMeta || {},
    alias: indexSAk6ucXsQXMeta?.alias || [],
    redirect: indexSAk6ucXsQXMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacydpJDnFbzfQMeta?.name ?? "privacy",
    path: privacydpJDnFbzfQMeta?.path ?? "/privacy",
    meta: privacydpJDnFbzfQMeta || {},
    alias: privacydpJDnFbzfQMeta?.alias || [],
    redirect: privacydpJDnFbzfQMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: team5jDEInuhgbMeta?.name ?? "team",
    path: team5jDEInuhgbMeta?.path ?? "/team",
    meta: team5jDEInuhgbMeta || {},
    alias: team5jDEInuhgbMeta?.alias || [],
    redirect: team5jDEInuhgbMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsbX2zCs9FSvMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsbX2zCs9FSvMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsbX2zCs9FSvMeta || {},
    alias: terms_45and_45conditionsbX2zCs9FSvMeta?.alias || [],
    redirect: terms_45and_45conditionsbX2zCs9FSvMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: termsBm4XQbO4QkMeta?.name ?? "terms",
    path: termsBm4XQbO4QkMeta?.path ?? "/terms",
    meta: termsBm4XQbO4QkMeta || {},
    alias: termsBm4XQbO4QkMeta?.alias || [],
    redirect: termsBm4XQbO4QkMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue").then(m => m.default || m)
  }
]